<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="submitQuestionnaire">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              Questionnaire
            </h5>
          </div>
          <div class="col-auto"
               v-if="isAllowed('psc.main.questionnaire.edit') && isOwnVessel('pscReport')"
          >
            <button class="e-btn e-btn-green ml-2" type="button" v-if="!toEdit" @click="editQuestionnaire">Edit Questionnaire</button>
            <template v-else>
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelEditQuestionAnswer">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" type="submit" :disabled="hasChanges === false">Save</button>
            </template>
          </div>
        </div>
        <table class="w-100">
          <template v-if="Object.keys(questionAnswers).length">
            <tr v-for="(questionAnswer, index) in questionAnswers">
              <td style="width: 50%; vertical-align: top;">{{ questionAnswer.psc_question.description }} <sub class="lead e-text-red font-weight-bold">*</sub></td>
              <td>
                <template v-if="toEdit">
                  <template v-if="questionAnswer.psc_question.psc_question_type.description === 'text'">
                    <input class="form-control-sm form-control mb-1" type="text" v-model="questionAnswers[index].answer">
                  </template>
                  <template v-if="questionAnswer.psc_question.psc_question_type.description === 'integer'">
                    <div class="row ml-0 align-items-center">
                      <input class="form-control-sm form-control mb-1 col-3" type="number" :step="0.00" :min="0.00" v-model="questionAnswers[index].answer">
                      <span class="font-weight-bold pl-2">{{questionAnswer.psc_question.id === 8 ? 'Hrs' : '' }}</span>
                    </div>
                  </template>
                  <template v-if="questionAnswer.psc_question.psc_question_type.description === 'boolean'">
                    <select class="form-control form-control-sm mb-1" v-model="questionAnswers[index].answer_value" style="width: 130px;"
                            @change="updateQuestionAnswer(index, questionAnswer.psc_question.psc_question_type.description)">
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </template>
                  <template v-if="questionAnswer.psc_question.psc_question_type.description === 'boolean-text'">
                    <div class="form-row mb-1 p-1">
                      <select class="form-control form-control-sm col-3" v-model="questionAnswers[index].answer_value" style="width: 130px;"
                              @change="updateQuestionAnswer(index, questionAnswer.psc_question.psc_question_type.description)">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                      </select>

                      <template v-if="!!questionAnswers[index].answer_value">
                        <input class="form-control form-control-sm col-9" type="text" v-model="questionAnswers[index].answer">
                      </template>
                    </div>
                  </template>
                  <template v-if="questionAnswer.psc_question.psc_question_type.description === 'options'">
                    <div class="form-row mb-1 p-1">
                      <select class="form-control form-control-sm col-3" v-model="questionAnswers[index].answer_value" style="width: 130px;"
                              @change="updateQuestionAnswer(index, questionAnswer.psc_question.psc_question_type.description)">
                        <option value="0" :disabled="questionAnswers[index].answer_value == 0">Select One</option>
                        <template v-for="option in questionAnswer.psc_question.psc_dropdown_options">
                          <option :value="option.id" :disabled="option.id == questionAnswers[index].answer_value">{{ option.value }}</option>
                        </template>
                      </select>
                    </div>
                  </template>
                </template>
                <template v-else>
                  {{ getAnswer(questionAnswer, 'N/A') }}
                </template>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="2" class="text-center">
              No Questionnaire Found.
            </td>
          </tr>

          <!--<editor-fold default-state="collapsed" desc="OLD">-->
          <!--          <tr>-->
          <!--            <td style="width: 50%">1) How many PSC/FSC officer boarded</td>-->
          <!--            <td>2</td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td style="width: 50%">2) What was the officer behaviour</td>-->
          <!--            <td>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="officerBehaviour" id="friendly" value="friendly" checked>-->
          <!--                <label class="form-check-label" for="friendly">Friendly</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="officerBehaviour" id="indifferent" value="indifferent">-->
          <!--                <label class="form-check-label" for="indifferent">Indifferent</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="officerBehaviour" id="aggressive" value="aggressive">-->
          <!--                <label class="form-check-label" for="aggressive">Aggressive</label>-->
          <!--              </div>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td style="width: 50%">3) What was the predominant area of the ispection</td>-->
          <!--            <td>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="predominantAreaInspection" id="deck" value="deck">-->
          <!--                <label class="form-check-label" for="deck">Deck</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="predominantAreaInspection" id="engine" value="engine">-->
          <!--                <label class="form-check-label" for="engine">Engine</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="predominantAreaInspection" id="both" value="both" checked>-->
          <!--                <label class="form-check-label" for="both">Both</label>-->
          <!--              </div>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td style="width: 50%">4) Was this concntrated Insp.Campain</td>-->
          <!--            <td>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="concentratedInspCampain" id="concentratedInspCampainYes" value="concentratedInspCampainYes" checked>-->
          <!--                <label class="form-check-label" for="concentratedInspCampainYes">YES</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="concentratedInspCampain" id="concentratedInspCampainNo" value="concentratedInspCampainNo">-->
          <!--                <label class="form-check-label" for="concentratedInspCampainNo">NO</label>-->
          <!--              </div>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td style="width: 50%">5) Was the ISPS code implementation examined</td>-->
          <!--            <td>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="ispsCodeImplementation" id="ispsCodeImplementationYes" value="ispsCodeImplementationYes" checked>-->
          <!--                <label class="form-check-label" for="ispsCodeImplementationYes">YES</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="ispsCodeImplementation" id="ispsCodeImplementationNo" value="ispsCodeImplementationNo">-->
          <!--                <label class="form-check-label" for="ispsCodeImplementationNo">NO</label>-->
          <!--              </div>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td style="width: 50%">6) Was the Cyber security examined</td>-->
          <!--            <td>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="cyberSecurityExam" id="cyberSecurityExamYes" value="cyberSecurityExamYes" checked>-->
          <!--                <label class="form-check-label" for="cyberSecurityExamYes">YES</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="cyberSecurityExam" id="cyberSecurityExamNo" value="cyberSecurityExamNo">-->
          <!--                <label class="form-check-label" for="cyberSecurityExamNo">NO</label>-->
          <!--              </div>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td style="width: 50%">-->
          <!--              7) Asking any bribes from the inspector-->
          <!--              <p class="pl-5 mb-0">If "YES", how much pay ??</p>-->
          <!--            </td>-->
          <!--            <td class="align-top">-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="inspectorBribe" id="inspectorBribeYes" value="inspectorBribeYes" checked>-->
          <!--                <label class="form-check-label" for="inspectorBribeYes">YES</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                <input class="form-check-input" type="radio" name="inspectorBribe" id="inspectorBribeNo" value="inspectorBribeNo">-->
          <!--                <label class="form-check-label" for="inspectorBribeNo">NO</label>-->
          <!--              </div>-->
          <!--              <div class="form-check form-check-inline" style="width: 75px;">-->
          <!--                100 USD-->
          <!--              </div>-->
          <!--            </td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td style="width: 50%">8) How long was the Inspection(embark-disembark)</td>-->
          <!--            <td>2.54</td>-->
          <!--          </tr>-->
          <!--</editor-fold>-->
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "@/mixins/ObjectMixin";
import {AlertService} from "@/services/AlertService";
import {permissionMixin} from "@/mixins/permissionMixins";
import {DataService} from "@/services/DataService";

export default {
  name: "PscQuestionnaire",
  data() {
    return {
      questionAnswers: {},
      toEdit: false,
    }
  },
  mixins: [ObjectMixin,permissionMixin],
  methods: {
    ...mapActions(['updatePscInfo']),
    getAnswer(questionAnswer, default_value = null) {
      let value = null;

      switch (questionAnswer?.psc_question?.psc_question_type?.description) {
        case 'text':
          value = questionAnswer.answer;
          break;
        case 'options':
          value = questionAnswer.answer_value ? parseInt(questionAnswer.answer_value) : 0;
          let option = questionAnswer.psc_question.psc_dropdown_options.filter(option => option.id == value);
          option = option.length ? option[0] : {};
          value = Object.keys(option).length ? option.value : null;
          break;
        case 'boolean':
          value = Boolean(questionAnswer.answer) ? 'Yes' : 'No';
          break;
        case 'boolean-text':
          value = Boolean(questionAnswer.answer_value) ? ('Yes: ' + questionAnswer.answer) : 'No';
          break;

        case 'integer':
          value = `${!isNaN(questionAnswer.answer) && questionAnswer.answer ? parseInt(questionAnswer.answer) : '0'}
                   ${questionAnswer.psc_question_id == 8 ? "hrs" : ''}`
        break;
      }

      if (value != null) {
        value = String(value).toUpperCase();
      }

      return value ?? default_value;
    },
    getQuestionnaire() {
      this.questionAnswers = JSON.parse(JSON.stringify(this.pscGet('question_answers', {})));
    },
    editQuestionnaire() {
      this.toEdit = true;
      this.getQuestionnaire();
    },
    resetQuestionnaireFields() {
      this.toEdit = false;
      this.getQuestionnaire();
    },
    async submitQuestionnaire() {
      if (await AlertService.confirmUpdateAlert("Do you want to Update?")) {
        const params = {
          __return_response: true,
          id: this.pscGet('id'),
          question_answers: this.questionAnswers,
        };

        const response = await this.updatePscInfo(params);

        if (response.status === 200) {
          AlertService.successAlert('Questionnaire has been Updated Successfully!', 'UPDATE PSC');
          this.resetQuestionnaireFields();
        }
      }
    },
    updateQuestionAnswer(index, type = 'text') {
      let answer = this.questionAnswers[index].answer;
      let answer_value = this.questionAnswers[index].answer_value;

      switch (type) {
        case 'boolean':
        case 'options':
          this.questionAnswers[index].answer = answer_value ? answer_value : null;
          break;
        case 'boolean-text':
          this.questionAnswers[index].answer = answer_value ? answer : null;
          break;
      }
    },
    async cancelEditQuestionAnswer() {
      if (await AlertService.cancelAlert()) {
        this.resetQuestionnaireFields();
      }
    },
  },
  async created() {
    await this.getQuestionnaire();
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges()) {
        next();
      } else {
        next(false);
      }

    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      'pscReport'
    ]),
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      let hasChanges = false;
      this.pscReport.question_answers.forEach((question, index) => {
        let answer = this.questionAnswers[index].answer;

        if (this.questionAnswers[index].psc_question.psc_question_type_id === 4) {
          if (this.pscReport.question_answers[index].answer === '0') {
            answer = answer === '0' ? answer.toString() : answer;
          } else {
            answer = answer === '0' ? null : answer !== null ? answer.toString() : answer;
          }
        }

        let editedData = {
          answer : answer === '' ? null : answer
        }

        let hasChange = DataService.checkIfChanged(this.pscReport.question_answers[index], editedData);

        if (hasChange === true) {
          hasChanges = true;
        }
      });

      return hasChanges;
    }
  },
}
</script>

<style scoped>

</style>
